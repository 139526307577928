/**
 * 获取二级域名
 * @returns {string} 返回二级域名或完整域名
 * @example
 * // 如果当前域名是 app.example.com
 * getSecondLevelDomain() // 返回 example.com
 * // 如果当前域名是 example.com
 * getSecondLevelDomain() // 返回 example.com
 */
export function getSecondLevelDomain() {
    const hostname = window.location.hostname;
    const parts = hostname.split(".");

    // 如果域名部分有三个以上的部分（如 xxx.zz.com）则提取二级域名, 否则返回完整域名
    if (parts.length > 2) {
        return parts.slice(-2).join(".");
    } else {
        return hostname;
    }
}
