import React from 'react';
import styled from 'styled-components';

const PricingSection = styled.section`
  padding: 5rem 2rem;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const SectionTitle = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: #333;
`;

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const PricingCard = styled.div`
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: center;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-5px);
  }
`;

const PlanName = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #333;
`;

const PlanButton = styled.button`
  padding: 0.8rem 2rem;
  background-color: #6366f1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #4f46e5;
  }
`;

function Pricing() {
  const plans = [
    {
      name: "基础版",
      features: [
        "最多10人会议",
        "720p视频质量",
        "基础会议功能",
        "基础客服支持",
        "移动端支持"
      ]
    },
    {
      name: "专业版",
      features: [
        "最多50人会议",
        "1080p视频质量",
        "高级会议功能",
        "24/7客服支持",
        "全平台支持",
        "会议录制"
      ]
    },
    {
      name: "企业版",
      features: [
        "无限人数",
        "4K视频质量",
        "全部高级功能",
        "专属客户经理",
        "API集成支持",
        "自定义部署方案"
      ]
    }
  ];

  return (
    <PricingSection id="pricing">
      <Container>
        <SectionTitle>版本对比</SectionTitle>
        <PricingGrid>
          {plans.map((plan, index) => (
            <PricingCard key={index}>
              <PlanName>{plan.name}</PlanName>
              <ul style={{listStyle: 'none', padding: 0, marginBottom: '2rem'}}>
                {plan.features.map((feature, i) => (
                  <li key={i} style={{margin: '0.8rem 0', color: '#666'}}>{feature}</li>
                ))}
              </ul>
              <PlanButton>了解详情</PlanButton>
            </PricingCard>
          ))}
        </PricingGrid>
      </Container>
    </PricingSection>
  );
}

export default Pricing; 