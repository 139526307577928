import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from "js-cookie";
import { getSecondLevelDomain } from '../utils/domain';

const ID_TOKEN_COOKIE = "id_token";
const COOKIE_EXPIRE_DAYS = 30;
const MEETING_MAINPAGE_URL = "https://app.aimeet.cc";   // https://app.aimeet.cc | http://localhost:3004

const HeroSection = styled.section`
  min-height: 80vh;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0rem 2rem;

  @media (max-width: 768px) {
    padding: 0rem 1rem;
    min-height: auto;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2rem;
  padding-top: 0.1rem;

  @media (max-width: 768px) {
    gap: 1.5rem;
  }
`;

const HeroContent = styled.div`
  max-width: 800px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 1.5rem;
  white-space: nowrap;
  color: #1a1a1a;
  
  @media (max-width: 768px) {
    font-size: 1.8rem;
    white-space: normal;
    margin-bottom: 1rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.25rem;
  margin-bottom: 2rem;
  color: #333;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
`;

const CTAButton = styled.button`
  padding: 1rem 2rem;
  font-size: 1.1rem;
  background-color: #0062ff;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;

  @media (max-width: 768px) {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    width: 100%;
  }

  &:hover {
    transform: translateY(-2px);
    background-color: #0051d6;
  }
`;

const HeroImage = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 0 1rem;

  @media (max-width: 768px) {
    padding: 0;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 0.75rem;
  }
`;

const WatchDemoButton = styled.button`
  padding: 1rem 2rem;
  font-size: 1.1rem;
  background-color: white;
  color: #0062ff;
  border: 1px solid #0062ff;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;

  @media (max-width: 768px) {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    width: 100%;
  }

  &:hover {
    background-color: #f5f8ff;
    transform: translateY(-2px);
  }
`;

function Hero() {
  const { user, isLoading, loginWithPopup, getIdTokenClaims } = useAuth0();
  const [ initialAuthenticated, setInitialAuthenticated ] = useState(true);
  const idTokenCookie = Cookies.get(ID_TOKEN_COOKIE);

  useEffect(() => {
    if (!isLoading) {
      const authed = user !== undefined;
      setInitialAuthenticated(authed);

      if (user) {
        if (authed && !initialAuthenticated) {
          window.location.replace(MEETING_MAINPAGE_URL);
        }
      }
    }
  }, [user, isLoading]);

  const handleTryForFree = async () => {
    if (idTokenCookie) {
      window.location.replace(MEETING_MAINPAGE_URL);
    } else {
      await loginWithPopup();
      const idToken = await getIdTokenClaims();

      if (idToken) {
        Cookies.set(ID_TOKEN_COOKIE, JSON.stringify(idToken), {
          domain: getSecondLevelDomain(),
          expires: COOKIE_EXPIRE_DAYS,
        });
      }
    }
  };

  const scrollToDemo = () => {
    const demoSection = document.getElementById('demo-video-section');
    if (demoSection) {
      demoSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <HeroSection>
      <Container>
        <HeroContent>
          <Title>Innovative video conferencing platform</Title>
          <Subtitle>
            You can always connect with others and communicate effectively
          </Subtitle>
          <ButtonGroup>
            <CTAButton onClick={handleTryForFree}>
              Try for free
            </CTAButton>
            <WatchDemoButton onClick={scrollToDemo}>
              Watch Demo
            </WatchDemoButton>
          </ButtonGroup>
        </HeroContent>
        <HeroImage>
          <img 
            src="/hero-image.png" 
            alt="Video conference illustration" 
          />
        </HeroImage>
      </Container>
    </HeroSection>
  );
}

export default Hero; 