import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from "js-cookie";
import { getSecondLevelDomain } from '../utils/domain';

const ID_TOKEN_COOKIE = "id_token";
const COOKIE_EXPIRE_DAYS = 30;
const MEETING_MAINPAGE_URL = "https://app.aimeet.cc";   // https://app.aimeet.cc | http://localhost:3004

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 0.75rem 1rem;
  }
`;

const LogoContainer = styled.div`
  height: 32px;
  width: 157px;

  @media (max-width: 768px) {
    height: 28px;
    width: 137px;
  }
`;

const LogoImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const GetStartedButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #0062ff;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;

  @media (max-width: 768px) {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }

  &:hover {
    background-color: #0051d6;
  }
`;

const NavbarSpacer = styled.div`
  height: 64px;

  @media (max-width: 768px) {
    height: 56px;
  }
`;

function Navbar() {
  const { user, isLoading, loginWithPopup, getIdTokenClaims } = useAuth0();
  const [ initialAuthenticated, setInitialAuthenticated ] = useState(true);
  const idTokenCookie = Cookies.get(ID_TOKEN_COOKIE);

  useEffect(() => {
    if (!isLoading) {
      const authed = user !== undefined;
      setInitialAuthenticated(authed);

      if (user) {
        if (authed && !initialAuthenticated) {
          window.location.replace(MEETING_MAINPAGE_URL);
        }
      }
    }
  }, [user, isLoading]);

  const handleGetStarted = async () => {
    if (idTokenCookie) {
      window.location.replace(MEETING_MAINPAGE_URL);
    } else {
      await loginWithPopup();
      const idToken = await getIdTokenClaims();

      if (idToken) {
        Cookies.set(ID_TOKEN_COOKIE, JSON.stringify(idToken), {
          domain: getSecondLevelDomain(),
          expires: COOKIE_EXPIRE_DAYS,
        });
      }
    }
  };

  return (
    <>
      <Nav>
        <LogoContainer>
          <LogoImage src="/aimeet.png" alt="Aimeet Logo" />
        </LogoContainer>
        <GetStartedButton onClick={handleGetStarted}>
          {idTokenCookie ? 'Meeting' : 'Get Started'}
        </GetStartedButton>
      </Nav>
      <NavbarSpacer />
    </>
  );
}

export default Navbar;