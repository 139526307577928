import React, { useState } from 'react';
import styled from 'styled-components';
import YouTube from 'react-youtube';

const FeaturesSection = styled.section`
  padding: 5rem 2rem;
  background-color: #f8f9fa;

  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const SectionTitle = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const FeatureCard = styled.div`
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const FeatureIcon = styled.div`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #6366f1;

  @media (max-width: 768px) {
    font-size: 1.75rem;
    margin-bottom: 0.75rem;
  }
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }
`;

const FeatureDescription = styled.p`
  color: #666;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 0.95rem;
  }
`;

const VideoSection = styled.div`
  margin-top: 5rem;
  text-align: center;

  @media (max-width: 768px) {
    margin-top: 3rem;
  }
`;

const VideoTitle = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);

  @media (max-width: 768px) {
    border-radius: 8px;
  }
`;

const VideoPlaceholder = styled.div`
  width: 100%;
  height: ${props => props.height}px;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  color: #666;

  @media (max-width: 768px) {
    height: 240px;
  }
`;

const PlaceholderIcon = styled.div`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const PlaceholderText = styled.p`
  font-size: 1rem;
  margin: 0;
`;

function Features() {
  const [ videoError, setVideoError ] = useState(false);

  const features = [
    {
      icon: "🌍",
      title: "Translation",
      description: "Support realtime translation, breaking through language communication barriers"
    },
    {
      icon: "📝",
      title: "Transcript",
      description: "Convert audio to text in real time for easy review of meeting content"
    },
    {
      icon: "📋",
      title: "Recaps",
      description: "You can recap transcript, translate and chat text messages after meeting end"
    },
    {
      icon: "♾️",
      title: "Unlimited",
      description: "You can create a meeting anytime and no time limit"
    },
    {
      icon: "🖥️",
      title: "Share Screen",
      description: "Support screen sharing, you can express your ideas more effectively"
    },
    {
      icon: "✨",
      title: "Excellent quality",
      description: "High quality audio and video, bring for better conversations"
    }
  ];

  const getVideoHeight = () => {
    if (window.innerWidth <= 768) {
      return '240';  // 移动端视频高度
    }
    return '450';    // 桌面端视频高度
  };

  const opts = {
    height: getVideoHeight(),
    width: '100%',
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
      rel: 0,
      playsinline: 1
    },
  };

  const onReady = (event) => {
    console.log('Video is ready');
    setVideoError(false);
  };

  const onError = () => {
    console.log('Video failed to load');
    setVideoError(true);
  };

  return (
    <FeaturesSection id="features">
      <Container>
        <SectionTitle>Why Aimeet</SectionTitle>
        <FeatureGrid>
          {features.map((feature, index) => (
            <FeatureCard key={index}>
              <FeatureIcon>{feature.icon}</FeatureIcon>
              <FeatureTitle>{feature.title}</FeatureTitle>
              <FeatureDescription>{feature.description}</FeatureDescription>
            </FeatureCard>
          ))}
        </FeatureGrid>
                
        <VideoSection id="demo-video-section">
          <VideoTitle>Watch Aimeet Feature Demo</VideoTitle>
          <VideoContainer>
            {
              videoError ? (
                <VideoPlaceholder height={450}>
                  <PlaceholderIcon>🎥</PlaceholderIcon>
                  <PlaceholderText>Video content is currently unavailable</PlaceholderText>
                </VideoPlaceholder>
                ) : (
                  <YouTube
                    videoId="hC4pxNR5Zd8"
                    opts={opts}
                    onReady={onReady}
                    onError={onError}
                  />
                )
            }
          </VideoContainer>
        </VideoSection>
      </Container>
    </FeaturesSection>
  );
}

export default Features; 